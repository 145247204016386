


@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100..900&display=swap);


body {
	opacity: 0;
	-webkit-transition: opacity 0.5s;
	transition: opacity 0.5s;
	width: 750px;
}

html.cjrc-ready body {
	opacity: 1;
	width: auto;
}







* {
	margin: 0;
	padding: 0;
	-webkit-text-size-adjust: 100%;
	max-height: 999999px;
}

html {
	overflow-y: scroll;
}

body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, form, fieldset, input, textarea, p, blockquote, th, td {
	margin: 0;
	padding: 0;
}

address, caption, cite, code, dfn, em, th, var {
	font-style: normal;
	font-weight: normal;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

q {
	&:before, &:after {
		content: '';
	}
}

object, embed {
	vertical-align: top;
}

hr, legend {
	display: none;
	border: none;
}

abbr, acronym, fieldset {
	border: 0;
}

tr, th, td, caption {
	vertical-align: top;
	font-style: normal;
	font-weight: normal;
	text-align: left;
}

ol, ul {
	list-style: none;
}

h1, h2, h3, h4, h5, h6 {
	font-style: normal;
	font-weight: normal;
	font-size: 100%;
}

img {
	border: 0;
	font-size: 0;
	line-height: 0;
	vertical-align: bottom;
}


